import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import Cookies from "js-cookie";
import game_overview_thumb from "../Media/Images/images/game_overview_thumb.png";

const SectionThree = (list) => {
  //To go on any other page
  // const cookieValue = Cookies.get("gamestation");
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);

  //To Handle MostPlayed Button
  const handleMostPlayedButton = () => {
    list.list.forEach((e) => {
      if (e.category === "mostPlayed") {
        // console.log("category ",e.category);
        // console.log("games ",e.game);
        localStorage.setItem("games", JSON.stringify(e.game));
        navigate("/seeAll");
      }
    });
  };

  return (
    <div className="breadcrumb-area breadcrumb-bg game-overview-breadcrumb">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-4 col-lg-6">
            <div className="game-overview-img">
              <img
                src={game_overview_thumb}
                // src="img/images/game_overview_thumb.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-8 col-lg-6">
            <div className="breadcrumb-content text-center text-lg-left pl-80">
              <h2>
                {language === "am" ? `በብዛት የተጫወቱ ጨዋታዎች ` : `Most Played Games `}
                <>{new Date().getFullYear()}</>
              </h2>
              <div className="game-overview-status">
                <ul>
                  <li>{/* <span>Total Wins :</span>- */}</li>
                  <li>{/* <span>Membership :</span>- */}</li>
                  {/* <li>
                    <span>Categories :</span>Platinum ||
                  </li> */}
                </ul>
              </div>
              <span
                onClick={() => {
                  handleMostPlayedButton();
                }}
                className="btn btn-style-two"
              >
                {language === "am" ? `በብዛት የተጫወቱትን ይሞክሩ` : `Try Most Played...`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SectionThree;
