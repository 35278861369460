import { combineReducers } from "redux";
import { reducer } from "./SendReducer";
import { languageReducer } from "./LanguageReducer";

const reducers = combineReducers({
  data: reducer,
  language: languageReducer,
});

export { reducers };
