import React from "react";
import { useSelector } from "react-redux";

const SmsInfoModal = ({ isOpen, onClose }) => {
  const language = useSelector((state) => state.language.language);
  return (
    <div className={`sms-info-modal ${isOpen ? "open" : ""}`} onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <p>
          {language === "am"
            ? `  እባክዎ ለዚህ አገልግሎት ለመመዝገብ  ወደ 9356 OK ብለው ይላኩ።`
            : `Please send ok to 9356 to subscribe to this service`}
        </p>
      </div>
    </div>
  );
};

export default SmsInfoModal;

// Please send ok to <SC> to subscribe to this servic
