import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import mobileImage from "../Media/Images/slider/slider_bg.png";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import { useSelector } from "react-redux";
import SmsInfoModal from "../Components/SmsInfoModal";
import axios from "axios";
const AlertMessage = ({ message, onClose }) => {
  return (
    <div className="alert-message">
      <p>{message}</p>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

const MobileNumberForm = () => {
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);
  const [mobileNumber, setMobileNumber] = useState("251");
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Handle the submission of the mobile number (e.g., send it to a server)
    // console.log("Submitted Mobile Number:", mobileNumber);
    // setShowModal(true); // Show the modal after submission
    if (mobileNumber.length > 10) {
      // Handle the submission of the mobile number (e.g., send it to a server)
      console.log("Submitted Mobile Number:", mobileNumber);
      axios
        // .post("http://localhost:3099/check-user", {
        .post("/check-user", {
          phone: mobileNumber,
          serviceName: "game-station",
          // Add other data properties as needed
        })
        .then((response) => {
          console.log(response);
          if (response.data === 0) {
            setShowModal(true); // Show the modal after submission
          } else {
            console.log("valid user");
            Cookies.set("game-station", mobileNumber, { expires: 3 });
            navigate("/");
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      // Display an alert message at the lower part of the page
      setAlertMessage("Please enter a valid mobile number.");
      setShowAlert(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Header />
      <div style={{ textAlign: "center" }}>
        <img
          src={mobileImage}
          alt="Mobile Image"
          style={{ width: "100%", maxWidth: "1000px" }}
        />
        <form onSubmit={handleSubmit} style={{ marginTop: "40px" }}>
          <label>
            {language === "am"
              ? `እባክዎ የስልክ ቁጥሮን ያስገቡ`
              : `Enter your mobile number:`}
            <input
              required
              type="text"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              placeholder="251"
              style={{ marginLeft: "10px", padding: "5px" }}
            />
          </label>
          <br />
          <button
            type="submit"
            style={{
              marginTop: "10px",
              padding: "8px 16px",
              border: "2px solid #007BFF",
              borderRadius: "4px",
              backgroundColor: "#007BFF",
              color: "#ffffff",
              cursor: "pointer",
            }}
          >
            {language === "am" ? `አስረክብ` : `Submit`}
          </button>
        </form>
        {showAlert && (
          <AlertMessage message={alertMessage} onClose={closeAlert} />
        )}
      </div>
      <SmsInfoModal isOpen={showModal} onClose={closeModal} />
      <div style={{ padding: "10px", textAlign: "center", font: "black" }}>
        <div>
          <p>
            {language === "am" && <> ሰብስክራይብ የሚለውን ጠቅ በማድረግ ተስማምተሃል</>}

            {language === "am" && (
              <u>
                {" "}
                <b onClick={() => navigate("/tnc")}>አተገባበሩና ​​መመሪያው</b>
              </u>
            )}
            {language === "en" && (
              <> By clicking Subscribe, you agree to our </>
            )}
            {language === "en" && (
              <u>
                <b onClick={() => navigate("/tnc")}>Terms and Conditions:</b>
              </u>
            )}
          </p>
          <ul>
            <li>
              {language === "am"
                ? `በ GameStation ለ 2ETB ለ1 ቀን ይመዘገባሉ።`
                : `You will subscribe in GameStation for 2ETB for 1 day.`}
            </li>
            <li>
              {language === "am"
                ? `አሁን ይመዝገቡ እና ለ 3 ቀናት ነፃ ጨዋታዎችን ያግኙ።`
                : ` Subscribe Now and Get 3 days free access.`}
            </li>
            <li>
              {language === "am"
                ? `* የደንበኝነት ምዝገባዎን ለመሰረዝ፣ እባክዎን STOP ብለው ወደ 9356 (በነጻ) ይላኩ።`
                : `To cancel your subscription, please send STOP GSUIT to 9356 (for`}
              free).
            </li>
            <li>
              {language === "am"
                ? `* ከድረ-ገጹ ለመሰረዝ እባክዎ ከገቡ በኋላ ወደ የእኔ መለያ ክፍል ይሂዱ እና ከደንበኝነት ምዝገባ ውጣ የሚለውን ይጫኑ።`
                : `
             To cancel from the website please go to My Account section after
             logging in and click on unsubscribe.
            `}
            </li>
            <li>
              {language === "am"
                ? `* ለማንኛውም ጥያቄ እባክዎ በ team@star-tech.website ላይ ያግኙን።`
                : `For any inquiries please contact us at team@star-tech.website.`}
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MobileNumberForm;
