import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logo_gameit from "../Media/Documentation/assets/img/GameStation.png";

import { changeLanguageAction } from "../Redux/Action";
import { useDispatch } from "react-redux";

const Header = (data) => {
  //To go on other page
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookieValue = Cookies.get("game-station");

  //State to store search results
  const [searchList, setSearchList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  //Method to show entered text
  const handleSearch = (search) => {
    // console.log("search is ",search);
    // console.log("data is ",data);

    let arr = [];
    data.data.forEach((e) => {
      e.game.forEach((f) => {
        // console.log("f ",f.name);

        if (
          search !== "" &&
          search !== " " &&
          (f.name.toLowerCase().startsWith(search) || f.name.startsWith(search))
        ) {
          // console.log(f.name);
          arr.push(f.name);
        }
      });
    });
    // console.log("arry is ",arr);
    setSearchList(arr);
  };

  //Method to play that search game by its name
  const handleSearchPlay = (name) => {
    // console.log("game name is ",name);

    //Get Selected Game
    data.data.forEach((e) => {
      e.game.forEach((f) => {
        if (f.name === name) {
          // console.log(f.game);
          window.location.href = f.game;
        }
      });
    });
  };

  const handleLanguageChange = (event) => {
    // console.log(event.target.value);
    setSelectedLanguage(event.target.value);
    dispatch(changeLanguageAction(event.target.value));
  };

  const logoutHandler = () => {
    console.log("clicked");
    // cookieValue.remove("myCookie");
    Cookies.remove("game-station");
    navigate("/");
  };
  const handleSubmit = () => {
    alert(`Selected Language Code: ${selectedLanguage}`);
    // You can replace the alert with your desired functionality
  };

  return (
    <header>
      <div id="sticky-header" className="main-header">
        <div className="container-fluid container-full-padding">
          <div className="row">
            <div className="col-12">
              <div className="main-menu">
                <nav>
                  <div className="logo">
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      <img
                        src={logo_gameit}
                        //   src="../Documentation/assets/img/logo_gameit.png"
                        alt="Logo"
                      />
                    </span>
                  </div>
                  <div
                    id="mobile-menu"
                    className="navbar-wrap d-none d-lg-flex"
                  >
                    <ul>
                      <li className="show">
                        <a href="#Sports">SPORTIFS</a>
                      </li>
                      <li>
                        <a href="#Funny">Funny</a>
                      </li>
                      <li>
                        <a href="#Action">D'ACTION</a>
                      </li>
                      <li>
                        <a href="#Adventure">D'AVENTURE</a>
                      </li>
                      <li>
                        <a href="#Racing">Racing</a>
                      </li>
                      <li>
                        <a href="#Kids">Kids</a>
                      </li>
                      <li>
                        <a href="#Arcade">ARCADE</a>
                      </li>
                      <li>
                        <a href="#Puzzle">PUZZLE</a>
                      </li>
                    </ul>
                  </div>
                  <div id="mobile-menu" className="navbar-wrap d-lg-flex">
                    <ul>
                      {" "}
                      <li
                        style={{
                          margin: "auto",
                        }}
                      >
                        <select
                          id="dropdown"
                          value={selectedLanguage}
                          onChange={handleLanguageChange}
                          style={{ borderRadius: "10px", padding: "6px" }}
                        >
                          <option value="en">EN</option>
                          <option value="am">AM</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  {cookieValue !== undefined ? (
                    <div id="mobile-menu" className="navbar-wrap d-lg-flex">
                      <ul>
                        {" "}
                        <li
                          style={{
                            margin: "auto",
                          }}
                        >
                          <button
                            onClick={logoutHandler}
                            style={{
                              backgroundColor: "white",
                              borderRadius: "8px",
                              padding: "8px",
                              cursor: "pointer",
                            }}
                          >
                            logout
                          </button>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="header-action">
                    <ul>
                      <li className="header-search">
                        <a
                          data-toggle="modal"
                          data-target="#search-modal"
                          href="/"
                        >
                          <i className="fas fa-search"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    {/* <select
                          id="languages"
                          value={selectedLanguage}
                          onChange={handleLanguageChange}
                        >
                          <option value="en">EN</option>
                          <option value="am">AM</option>
                        </select> */}
                  </div>
                </nav>
              </div>
              <div className="mobile-menu"></div>
            </div>
            {/* <!-- Modal Search --> */}
            <div
              className="modal fade"
              id="search-modal"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form>
                    <input
                      type="text"
                      placeholder="Search here..."
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                    />
                    <button>
                      <i className="fa fa-search"></i>
                    </button>
                    <div className="search-main-div">
                      {searchList.length > 0 &&
                        searchList.map((val, key) => {
                          // console.log("v ",val);
                          return (
                            <div key={key} className="search-div">
                              <span
                                className="search-span"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleSearchPlay(val);
                                }}
                              >
                                {val}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
