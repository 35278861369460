import React from "react";
import { useSelector } from "react-redux";
import Header from "../Components/Header";

const Tnc = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <div>
      <Header />
      {language === "am" ? (
        <div style={{ padding: "20px" }}>
          <h1 style={{ color: "black", fontSize: "20px" }}>
            እነዚህ ውሎች እና ሁኔታዎች በ GameStation አጠቃቀም ላይ ተፈጻሚ ይሆናሉ።
          </h1>
          <p>
            ይህንን አገልግሎት በመጠቀም ደንበኛው ለእነዚህ ውሎች እና ሁኔታዎች እውቅና ይሰጣል እና ይስማማል።
            GameStation የደንበኝነት ምዝገባ አገልግሎት ነው። በሞባይል ሂሳብዎ ላይ ክፍያዎች ይከፈላሉ ወይም
            ከቅድመ ክፍያ ሂሳብዎ ይቀነሳሉ።
          </p>
          <h2>1. የመግቢያ ዘዴ</h2>
          <p>
            አንዴ የጨዋታ ጣቢያ ደንበኝነት ምዝገባዎን ከገቡ በኋላ ይዘቱን ማግኘት እንዲችሉ አገናኝ ያለው ኤስኤምኤስ
            ይደርስዎታል። የመረጡትን ይዘት ለማጫወት መመሪያዎቹን ይከተሉ።
          </p>
          <h2>2. ተጠያቂነት</h2>
          <p>
            2.1. የአስተዋዋቂው እና የአስተዳዳሪው ኩባንያ ተጠያቂነት በእውነቱ በተከፈለው የደንበኝነት ምዝገባ መጠን
            ላይ ብቻ የተገደበ መሆን አለበት.
          </p>
          <p>
            2.2 በስምምነቱ መጀመሪያ ላይ ሊተነብይ ላልጠበቅነው ጥፋት ወይም ጉዳት፣ ወይም በመረጃ፣ በሥርዓት፣
            በመሳሪያዎች ላይ ለሚደርሰው ጥፋት ወይም ሙስና ተጠያቂ አንሆንም።
          </p>
          <p>
            2.3. ማንኛውንም የመድን ሽፋን በተመለከተ፣ የአስተዋዋቂው እና የአስተዳዳሪው ኩባንያ ተጠያቂነት በተገቢው
            የኢንሹራንስ ፖሊሲ መሠረት ሊመለሱ በሚችሉ ድምሮች ላይ ብቻ የተገደበ ይሆናል።
          </p>
          <h2>3. የኢሜል እና የሞባይል ግብይት ውሎች</h2>
          <p>
            የሞባይል ቁጥርዎን እና ወይም የኢሜል አድራሻዎን በመስጠት፣ከቡድናችን የሚመጡትን የቅርብ ጊዜ ቅናሾች እና
            ማስተዋወቂያዎችን ለማሳወቅ ሄርቤውቲ እና ማኔጅመንት ኩባንያው በአጋጣሚ ኢሜይል ወይም ጽሑፍ እንዲልኩልዎ
            ተስማምተዋል። ከማንኛቸውም የኢሜል/የፅሁፍ ስርጭታችን መሰረዝ ከፈለጉ በእያንዳንዱ ኢሜል ላይ
            'UNSUBSCRIBE' የሚል አገናኝ ይሰጥዎታል።
          </p>
          <h2>4. ምደባ HerBeauty</h2>
          <p>ለእነዚህ ውሎች ስምምነቱን የመመደብ እና የመቀበል መብቱ የተጠበቀ ነው።</p>
          <h2>5. HerBeautyን ያነጋግሩ</h2>
          <p>የእውቂያ ኢሜይል: support@dharam.net</p>
          <h2>6. በእነዚህ ውሎች ላይ ለውጦች</h2>
          <p>
            ዓላማችን ከፍተኛ ደረጃዎችን ለማሟላት ነው እና ፖሊሲዎቻችን እና አካሄዶቻችን በየጊዜው እየተገመገሙ ነው።
            ከጊዜ ወደ ጊዜ ውላችንን ማዘመን እንችላለን :: በዚህ መሠረት የአሁን ፖሊሲዎቻችንን ለመገምገም እነዚህን
            ገጾች በየጊዜው እንዲፈትሹ እንመክራለን።
          </p>
          <h2>7. የንግድ ምልክቶች / አርማዎች / ምስሎች</h2>
          <p>የንግድ ምልክቶች, የአገልግሎት ምልክቶች, አርማዎች </p>(ያለገደብ ጨምሮ, የምርቶች የግ
        </div>
      ) : (
        <div style={{ padding: "10px" }}>
          {" "}
          <h2 style={{ color: "black", fontSize: "20px" }}>
            Terms and Conditions
          </h2>
          <p>
            These terms and conditions apply to the use of GameStation. By using
            this service, the client acknowledges and agrees to these terms and
            conditions. GameStation is a subscription service. Charges will be
            made to your mobile bill or deducted from your prepaid account.
            Carrier fees or data charges may apply.
          </p>
          <section>
            <h3>1. Entry method</h3>
            <p>
              Once you have entered your Game Station subscription, you will
              receive an SMS with a link to be able to access the content.
              Follow the instructions to play the content of your choice.
            </p>
          </section>
          <section>
            <h3>2. Liability</h3>
            <p>
              2.1. The Liability of the promoter and its managing company shall
              be limited to the amount of the subscription charges actually
              paid.
            </p>
            <p>
              2.2. We shall not be liable for any loss or damage that we could
              not have reasonably been expected to foresee at the start of the
              agreement, or for any loss of or corruption to data, systems,
              equipment.
            </p>
            <p>
              2.3. In respect of any insured prize, the liability of the
              promoter and its managing company shall be limited to the sums
              actually recoverable under the applicable insurance policy.
            </p>
          </section>
          <section>
            <h3>3. Email and Mobile Marketing Terms & Conditions</h3>
            <p>
              By giving us your mobile number and / or email address, you agree
              for HerBeauty and its managing company to send you either an
              occasional email or text, notifying you of the latest offers and
              promotions available from our group. If you ever wish to be
              removed from any of our email/text broadcasts, you will have a
              link provided on each email saying ‘UNSUBSCRIBE’.
            </p>
          </section>
          <section>
            <h3>4. Assignment</h3>
            <p>
              HerBeauty reserves the right to assign the agreement to and
              acceptance of these Terms & Conditions, in whole or in part, at
              any time without notice. The user may not assign any of his/her
              rights or obligations arising from their agreement to and
              acceptance of these Terms & Conditions.
            </p>
          </section>
          <section>
            <h3>5. Contact HerBeauty</h3>
            <p>Contact Email: support@dharam.net</p>
          </section>
          <section>
            <h3>6. Changes to these Terms & Conditions</h3>
            <p>
              We aim to meet high standards and our policies and procedures are
              therefore constantly under review. From time to time, we may
              update our Terms & Conditions and Privacy policies to optimize the
              user experience and to comply with legal and regulatory
              obligations. Accordingly, we recommend that you check these pages
              periodically in order to review our current policies.
            </p>
          </section>
          <section>
            <h3>7. Trademarks / Logos / Images</h3>
            <p>
              Trademarks, service marks, logos (including without limitation,
              the individual names of products & retailers) are the property of
              their owners. Brands/Logos/Images used have not been done with the
              participation in, or in any way reviewed by the owners of this
              advertisement nor have they authorized it.
            </p>
          </section>
          <p>@ 2022. All Rights Reserved</p>
        </div>
      )}
    </div>
  );
};

export default Tnc;
