import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
const SectionOne = (data) => {
  //To go on any other page
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);
  // console.log(" Section  1 lang ", language);

  const cookieValue = Cookies.get("game-station");

  console.log("cookieValue ==> ", cookieValue);

  //To handle play games
  const handlePlayGames = () => {
    data.data.forEach((e) => {
      if (e.category === "mostPlayed") {
        // console.log("selected ",e.game);
        localStorage.setItem("games", JSON.stringify(e.game));
        navigate("/seeAll");
      }
    });
  };

  const redirectToSubPage = () => {
    data.data.forEach((e) => {
      if (e.category === "mostPlayed") {
        // console.log("selected ",e.game);
        localStorage.setItem("games", JSON.stringify(e.game));
        navigate("/subscribe");
      }
    });
  };

  return (
    <section
      className={`${
        cookieValue === undefined
          ? "slider-area slider-bg"
          : "slider-area slider-bg-sub-user"
      }`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9">
            <div className="slider-content text-center">
              <h6 className="wow fadeInDown" data-wow-delay=".2s">
                {cookieValue && <>Games</>}
              </h6>

              <h2 className="tlt fix" data-in-effect="fadeInLeft">
                {cookieValue && (
                  <>
                    Game <span>STATION</span>
                  </>
                )}
              </h2>

              {cookieValue && (
                <p className="wow fadeInUp" data-wow-delay="2s">
                  {language === "am"
                    ? ` መዝናኛን እና እውቀትን ያለምንም ችግር የሚያዋህዱ ጨዋ ጨዋታዎችን ያግኙ።`
                    : `Discover cutting-edge games that seamlessly blend entertainment,
                fun, and knowledge.`}
                </p>
              )}
              {cookieValue && (
                <span
                  className="btn wow fadeInUp"
                  data-wow-delay="2.2s"
                  onClick={() => {
                    handlePlayGames();
                  }}
                >
                  {language === "am" ? `ተጫወቱ` : `Play Games`}
                </span>
              )}
              {cookieValue === undefined && (
                <span
                  className="btn wow fadeInUp button-position"
                  data-wow-delay="2.2s"
                  onClick={() => {
                    redirectToSubPage();
                  }}
                >
                  {language === "am" ? `ደንበኛ ይሁኑ` : `Subscribe Now`}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SectionOne;
