import React from "react";
import OwlCarousel from "react-owl-carousel";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const OwlSlider = (props) => {
  var cookieValue = Cookies.get("game-station");
  // console.log(cookieValue); // Log the cookie value to the console
  //To go On other page
  // console.log(props);
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);

  //Options for Owl Carousel
  const options = {
    items: 3,
    margin: 30,
    nav: true,
    loop: true,
    navText: [
      '<i class="arrow fa fa-angle-left"></i>',
      '<i class="arrow fa fa-angle-right"></i>',
    ],
    // margin: 30,
    responsiveClass: true,
    // nav: true,
    dots: false,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1.5,
      },
      400: {
        items: 1.5,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  //Method to Handle See All
  const seeAllButton = (category) => {
    // console.log("category is ",category);
    // console.log("props.data is ",props.data);
    gettingRequiredData(props.data, category);
  };

  //Getting Required Data
  const gettingRequiredData = (data, category) => {
    // console.log(data);
    data.forEach((e) => {
      if (category === e.category) {
        // console.log("games ",e.game);
        //Adding Games in Local Storage for Now - but will use Redux Here
        localStorage.setItem("games", JSON.stringify(e.games));
      }
    });
    navigate("/seeAll");
  };

  return (
    <section className="latest-games-area pt-120" id={props.id}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8">
            <div className="section-title mb-25">
              <h2>
                <span>{props.name}</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <OwlCarousel
              {...options}
              className="latest-games-active owl-carousel owl-theme"
            >
              {props.list.map((e, key) => {
                return (
                  <div className="latest-games-items mb-30" key={key}>
                    <div className="latest-games-thumb">
                      <a
                        href={cookieValue === undefined ? "subscribe" : e.game}
                      >
                        <img
                          src={e.image}
                          alt="Game SRC"
                          height="200px"
                          width="200px"
                        />
                      </a>
                    </div>
                    <div className="latest-games-content">
                      <div className="lg-tag">
                        <a
                          href="#/"
                          onClick={() => {
                            seeAllButton(props.name);
                          }}
                        >
                          {language === "am" ? e.categoryAmharic : e.category}
                        </a>
                      </div>
                      <p>
                        {e.name}
                        {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. */}
                      </p>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
            <div className="see-all">
              <button>
                <span
                  className="btn wow fadeInUp"
                  onClick={() => {
                    // console.log("PROMPTSSSS ", props);
                    seeAllButton(props.name);
                  }}
                >
                  {language === "am" ? `ተመልከቱ` : `SEE ALL`}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default OwlSlider;
