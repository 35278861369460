const initialLanguageState = {
  language: "en", // Default language is set to English ("en")
};

export const languageReducer = (state = initialLanguageState, action) => {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    // Handle other language-related actions if needed
    default:
      return state;
  }
};
